import { axiosInstance } from "./axios";
import { SessionStatus } from "./constants";
import type { 
  Keypoint,
  SetSessionStatusRequest,
  SetSessionMissingNetRequest,
  AdjustKeypointsRequest,
  FBUser,
  FBSession,
  FBKeyframe,
  SessionData,
  DoVideoInferenceRequest,
  DoVideoProcessingRequest,
  DoKeyframeInferenceRequest,
  AdminIgnoreSessionShotRequest,
  AdminEditSessionShotStatusRequest,
  AdminEditSessionShotPointsRequest,
  CopySessionRequest,
  CopyKeypointsRequest,
  DeletePauseRequest,
  AddPauseRequest,
  UpdateVideoUrlRequest,
  Backboard,
  Hoop,
  Net,
} from "./types";
import {
  REPLICATE_VIDEO_ID,
  REPLICATE_KEYFRAME_ID,
  MAX_VIDEO_LENGTH,
} from "./constants";

/**
 * Fetches all users from the API
 * @param page (number): Page to start on
 * @param limit (number): Number of users to fetch
 */
export const fetchUsers = async (page: number, limit: number): Promise<FBUser[]> => {
  return axiosInstance
  .get('/admin/users', { 
    params: {
      page: page,
      limit: limit,
    }
  })
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchUsers`", err);
  });
}

/**
 * Fetch a single user from that API
 * @param userId (string): ID of the user to fetch
 */
export const fetchUser = async (userId: string): Promise<FBUser> => {
  return axiosInstance
  .get(`/admin/users/${userId}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchUser`", err);
  });
}

/**
 * Fetches all sessions from the API
 * @param page (number): Page to start on
 * @param limit (number): Number of sessions to fetch
 */
export const fetchSessions = async (page: number, limit: number): Promise<FBSession[]> => {
  return axiosInstance
  .get('/admin/sessions', {
    params: {
      page: page,
      limit: limit,
    }
  })
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchSessions`", err);
  });
}

/**
 * Fetch session from the API
 * @param sessionId (string): ID of the session to fetch
 */
export const fetchSession = async (sessionId: string): Promise<SessionData> => {
  return axiosInstance
  .get(`/admin/sessions/${sessionId}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchSession`", err);
  });
}

/**
 * Fetches all sessions belonging to a user from the API
 * @param userId (string): ID of the user
 * @param page (number): Page to start on
 * @param limit (number): Number of sessions to fetch
 */
export const fetchUserSessions = async (
  userId: string,
  page: number, 
  limit: number,
): Promise<FBSession[]> => {
  return axiosInstance
  .get(`/admin/sessions/users/${userId}`, {
    params: {
      page: page,
      limit: limit,
    }
  })
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchSessions`", err);
  });
}

/**
 * Deletes a session
 * @param id (string): ID of the session to delete
 */
export const deleteSession = async (id: string): Promise<boolean> => {
  return axiosInstance
  .delete(`/admin/session/${id}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `deleteSession`", err);
  });
}

/**
 * Adjusts keypoints for session
 */
export const adjustKeypoints = async (
  keyframeId: string, 
  keypoints: Keypoint[],
  hoop?: Hoop,
  backboard?: Backboard,
  net?: Net,
): Promise<boolean> => {
  const body: AdjustKeypointsRequest = {
    keyframeId: keyframeId,
    keypoints: keypoints,
    hoop: hoop,
    backboard: backboard,
    net: net,
  };
  return axiosInstance
  .post(`/admin/keyframe/keypoints`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `adjustKeypoints`", err);
  });
}

export const fetchKeyframes = async (id: string): Promise<{ids: string[], keyframes: FBKeyframe[]}> => {
  return axiosInstance
  .get(`/admin/session/keyframes/${id}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchKeyframes`", err);
  });
}

export const fetchKeyframe = async (id: string): Promise<FBKeyframe> => {
  return axiosInstance
  .get(`/admin/keyframe/${id}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchKeyframe`", err);
  });
}

export const deleteKeyframe = async (id: string): Promise<boolean> => {
  return axiosInstance
  .post(`/admin/keyframe/${id}/delete`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `deleteKeyframe`", err);
  });
}

export const resetKeyframes = async (id: string): Promise<boolean> => {
  return axiosInstance
  .post(`/admin/session/keyframes/reset/${id}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `resetKeyframes`", err);
  });
}

/**
 * Do inference on session video
 */
export const doVideoInference = async (id: string, liteMode: boolean) => {
  const body: DoVideoInferenceRequest = {
    replicateVideoId: REPLICATE_VIDEO_ID,
    replicateKeyframeId: REPLICATE_KEYFRAME_ID,
    minChunkLength: 10,            // 10sec min
    maxLength: MAX_VIDEO_LENGTH,   // 40min max
    batchSize: 64,                 // batch 16 takes up 12GB RAM; A40 has 48GB
    sampleRate: 2,                 // sample rate of 2
    liteMode: liteMode,            // lite mode
  }
  return axiosInstance
  .post(`/admin/session/infer/${id}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `doVideoInference`", err);
  });
}

/**
 * Do processing on session video
 * @note This does everything after inference. Only use if 
 * inference was successful at some point
 */
export const doVideoProcessing = async (id: string) => {
  const body: DoVideoProcessingRequest = {
    processHighlights: true,
    processShots: true,
    processTagImages: true,
    maxLength: MAX_VIDEO_LENGTH,  // 40min max
  }
  return axiosInstance
  .post(`/admin/session/process/${id}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `doVideoProcessing`", err);
  });
}

/**
 * Do inference on session keyframes
 */
export const doKeyframeInference = async (id: string) => {
  const body: DoKeyframeInferenceRequest = {
    replicateId: REPLICATE_KEYFRAME_ID,
  }
  return axiosInstance
  .post(`/admin/session/keyframes/infer/${id}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `doKeyframeInference`", err);
  });
}

/**
 * Request video compression
 */
export const requestVideoCompression = async (id: string) => {
  return axiosInstance
  .post(`/admin/session/request/compress/${id}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `doVideoCompression`", err);
  });
}

/**
 * Remakes session highlights
 */
export const doHighlightProcessing = async (id: string) => {
  const body: DoVideoProcessingRequest = {
    processHighlights: true,
    processShots: false,
    processTagImages: false,
    maxLength: MAX_VIDEO_LENGTH,  // 40min max
  }
  return axiosInstance
  .post(`/admin/session/process/${id}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `doHighlightProcessing`", err);
  });
}

/**
 * Remakes session tag images
 */
export const doTagsProcessing = async (id: string) => {
  const body: DoVideoProcessingRequest = {
    processHighlights: false,
    processShots: false,
    processTagImages: true,
    maxLength: MAX_VIDEO_LENGTH,  // 40min max
  }
  return axiosInstance
  .post(`/admin/session/process/${id}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `doTagsProcessing`", err);
  });
}

/** 
 * Set session state
 */
export const setSessionStatus = async (id: string, status: SessionStatus): Promise<boolean> => {
  const body: SetSessionStatusRequest = {
    status: status.toString(),
  };
  return axiosInstance
  .post(`/admin/session/status/${id}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `setSessionStatus`", err);
  });
}

/** 
 * Set session missing net
 */
export const setSessionMissingNet = async (id: string, missingNet: boolean): Promise<boolean> => {
  const body: SetSessionMissingNetRequest = {
    missingNet: missingNet,
  };
  return axiosInstance
  .post(`/admin/session/net/${id}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `setSessionMissingNet`", err);
  });
}

/**
 * Copy session to another user
 * @param id (string): ID of the session to copy
 * @param userId (string): ID of the user to copy to (not the current session owner)
 * @return (FBSession): The copied session
 */
export const copySession = async (id: string, userId: string): Promise<boolean> => {
  const body: CopySessionRequest = {
    userId: userId,
    isExample: false,
  };
  return axiosInstance
  .post(`/admin/session/copy/${id}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `copySession`", err);
  });
}

/**
 * Copy keypoints from another keyframe
 */
export const copyKeypoints = async (targetKeyframeId: string, sourceKeyframeId: string): Promise<boolean> => {
  const body: CopyKeypointsRequest = {
    keyframeId: sourceKeyframeId,
  };
  return axiosInstance
  .post(`/admin/keyframe/copy/${targetKeyframeId}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `copyKeypoints`", err);
  });
}

/**
 * Delete paused timestamp (and keyframe)
 */
export const deletePause = async (sessionId: string, timestamp: number, keyframeId?: string): Promise<boolean> => {
  const body: DeletePauseRequest = {
    timestamp: timestamp,
    keyframeId: keyframeId,
  };
  return axiosInstance
  .post(`/admin/pauses/delete/${sessionId}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `deletePause`", err);
  });
}

/** 
 * Add a pause timestamp (and sort)
 */
export const addPause = async (sessionId: string, timestamp: number): Promise<boolean> => {
  const body: AddPauseRequest = {
    timestamp: timestamp,
  };
  return axiosInstance
  .post(`/admin/pauses/add/${sessionId}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `addPause`", err);
  });
}

/**
 * Update video URL for session
 */
export const updateVideoUrl = async (id: string, videoUrl: string): Promise<boolean> => {
  const body: UpdateVideoUrlRequest = {
    videoUrl: videoUrl,
  };
  return axiosInstance
  .post(`/admin/session/video/update/${id}`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `updateVideoUrl`", err);
  });
}

/**
 * Update session team names
 * @param body (IgnoreSessionShotRequest)
 * @returns (string)
 */
export const ignoreSessionShot = async (
  body: AdminIgnoreSessionShotRequest,
): Promise<boolean> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`/admin/session/shots/ignore`, body, config)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `ignoreSessionShot`:", err);
  });
}

/**
 * Update session shot status
 * @param body (EditSessionShotStatusRequest)
 * @returns (string)
 */
export const editSessionShotStatus = async (
  body: AdminEditSessionShotStatusRequest,
): Promise<boolean> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`/admin/session/shots/edit/status`, body, config)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `editSessionShotStatus`:", err);
  });
}

/**
 * Update session shot points
 * @param body (EditSessionShotPointsRequest)
 * @returns (string)
 */
export const editSessionShotPoints = async (
  body: AdminEditSessionShotPointsRequest,
): Promise<boolean> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`/admin/session/shots/edit/points`, body, config)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `editSessionShotPoints`:", err);
  });
}
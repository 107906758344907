import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import ReactFlow, { 
  MiniMap,
  Controls,
  Background,
  useNodesState, 
} from "reactflow";
import type { Node } from "reactflow";
import { Box, Button } from "@mui/material";
import 'reactflow/dist/base.css';
import toast from "react-hot-toast";
import KeypointNode from "./KeypointNode";
import ResizableNode from "./ResizableNode";
import ImageNode from "./ImageNode";
import LoadingView from "./LoadingView";
import type { 
  FBKeyframe, 
  Hoop,  
  Backboard,
  Net,
  Keypoint, 
} from "../shared/types";
import { 
  fetchKeyframe,
  adjustKeypoints, 
} from "../shared/services";
import { HOOPER_YELLOW } from "../shared/constants";

const nodeTypes = {
  keypoint: KeypointNode,
  image: ImageNode,
  box: ResizableNode,
}

const xFix: number = 4;
const yFix: number = -7;

// Adjust position a little to get the actual right position based 
// on the left bullet dot
const adjustPosition = (position: {x: number, y: number}) => {
  return {
    x: position.x - xFix,
    y: position.y - yFix,
  }
}

/**
 * KeyboardUI - UI to visualize keypoints and move them around
 */
const KeyboardUI = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);

  // Get the keyframe ID from the route params
  const params = useParams();
  const keyframeId = params.id;

  // Use the route params to see if we should show a net
  const location = useLocation();
  const missingNet: boolean = location.state?.missingNet || false;

  useEffect(() => {
    if (!keyframeId) return;
    handleOnFetchData(keyframeId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyframeId]);

  const handleOnFetchData = async (keyframeId: string) => {
    try {
      // Update the state variable
      const keyframe = await fetchKeyframe(keyframeId);
      // Update the nodes
      const nodes = getInitialNodes(keyframe);
      setNodes(nodes);
    } catch (err) {
      console.error("Error in `handleOnFetchData`: ", err);
    } finally {
      setLoading(false);
    }
  }

  // Construct nodes from keyframes
  const getInitialNodes = (keyframe: FBKeyframe) => {
    const keypointNodes = keyframe.keypoints.map((keypoint: Keypoint, index: number) => {
      return {
        id: (index+4).toString(), 
        type: 'keypoint', 
        position: {
          x: keypoint.x + xFix,
          y: keypoint.y + yFix,
        },
        data: { 
          label: keypoint.name,
        },
      }
    });
    const imageNode = {
      id: '0',
      type: 'image',
      position: { x: 0, y: 0 },
      data: {
        image: {
          url: keyframe.frameUrl,
          height: keyframe.height,
          width: keyframe.width,
        }
      },
      draggable: false,
      style: { pointerEvents: 'none' as 'none', zIndex: -1 } // Inline styles
    };
    const hoopNode = {
      id: '1',
      type: 'box',
      position: { 
        x: keyframe.hoop ? keyframe.hoop.box[0] : 0, 
        y: keyframe.hoop ? keyframe.hoop.box[1] : 0,
      },
      data: { 
        label: 'hoop', 
        color: '#FFC436', 
        rotatable: false,  
        // init width and height, these are only changing on load
        width: keyframe.hoop ? keyframe.hoop.box[2] - keyframe.hoop.box[0] : 10, 
        height: keyframe.hoop ? keyframe.hoop.box[3] - keyframe.hoop.box[1] : 10,
      },
      // actual width and height, these are adjusted by the NodeResizer
      width: keyframe.hoop ? keyframe.hoop.box[2] - keyframe.hoop.box[0] : 10, 
      height: keyframe.hoop ? keyframe.hoop.box[3] - keyframe.hoop.box[1] : 10,
    };
    const backboardNode = {
      id: '2',
      type: 'box',
      position: { 
        x: keyframe.backboard ? keyframe.backboard.box[0] : 0, 
        y: keyframe.backboard ? keyframe.backboard.box[1] : 0,
      },
      data: { 
        label: 'backboard', 
        color: '#F86F03', 
        rotatable: false, 
        // init width and height, these are only changing on load
        width: keyframe.backboard ? keyframe.backboard.box[2] - keyframe.backboard.box[0] : 10, 
        height: keyframe.backboard ? keyframe.backboard.box[3] - keyframe.backboard.box[1] : 10,
      },
      // actual width and height, these are adjusted by the NodeResizer
      width: keyframe.backboard ? keyframe.backboard.box[2] - keyframe.backboard.box[0] : 10, 
      height: keyframe.backboard ? keyframe.backboard.box[3] - keyframe.backboard.box[1] : 10,
    };
    const netNode = {
      id: '3',
      type: 'box',
      position: { 
        x: keyframe.net ? keyframe.net.box[0] : 0, 
        y: keyframe.net ? keyframe.net.box[1] : 0,
      },
      data: { 
        label: 'net', 
        color: '#B51B75', 
        rotatable: false, 
        // init width and height, these are only changing on load
        width: keyframe.net ? keyframe.net.box[2] - keyframe.net.box[0] : 10, 
        height: keyframe.net ? keyframe.net.box[3] - keyframe.net.box[1] : 10,
      }, 
      // actual width and height, these are adjusted by the NodeResizer
      width: keyframe.net ? keyframe.net.box[2] - keyframe.net.box[0] : 10, 
      height: keyframe.net ? keyframe.net.box[3] - keyframe.net.box[1] : 10,
    };
    let nodes: Node[] = [
      imageNode,
      hoopNode,
      backboardNode,
    ];
    if (!missingNet) {
      nodes.push(netNode);
    }
    nodes = [...nodes, ...keypointNodes];
    return nodes;
  }

  const handleOnSave = async () => {
    if (!keyframeId) return;
    const annotatedKeypoints: Keypoint[] = [];
    
    // Fetch image and its image shapes
    const image = nodes[0];
    const imageWidth: number = image.data.image.width;
    const imageHeight: number = image.data.image.height;

    // Get the bounds of the nodes
    const hoopBox = [
      nodes[1].position.x,
      nodes[1].position.y,
      nodes[1].position.x + nodes[1].width!,
      nodes[1].position.y + nodes[1].height!,
    ]
    const backboardBox = [
      nodes[2].position.x,
      nodes[2].position.y,
      nodes[2].position.x + nodes[2].width!,
      nodes[2].position.y + nodes[2].height!,
    ]
    const netBox = [
      nodes[3].position.x,
      nodes[3].position.y,
      nodes[3].position.x + nodes[3].width!,
      nodes[3].position.y + nodes[3].height!,
    ]
    // Fetch hoop
    let hoop: Hoop | undefined;
    if (
      hoopBox[0] > imageWidth  || hoopBox[0] < 0 || 
      hoopBox[1] > imageHeight || hoopBox[1] < 0 ||
      hoopBox[2] > imageWidth  || hoopBox[2] < 0 ||
      hoopBox[3] > imageHeight || hoopBox[3] < 0 || 
      (hoopBox[0] === 0 && hoopBox[1] === 0)
    ) {
      hoop = undefined;
    } else {
      hoop = {
        box: hoopBox, 
        score: 1.0,
        width: imageWidth,
        height: imageHeight,
      };
    }

    // Fetch backboard
    let backboard: Backboard | undefined;
    if (
      backboardBox[0] > imageWidth  || backboardBox[0] < 0 ||
      backboardBox[1] > imageHeight || backboardBox[1] < 0 || 
      backboardBox[2] > imageWidth  || backboardBox[2] < 0 ||
      backboardBox[3] > imageHeight || backboardBox[3] < 0 || 
      (backboardBox[0] === 0 && backboardBox[1] === 0)
    ) {
      backboard = undefined;
    } else {
      backboard = {
        box: backboardBox,
        score: 1.0,
        width: imageWidth,
        height: imageHeight,
      }
    }

    // Fetch net
    let net: Net | undefined;
    if (missingNet) {
      net = undefined;
    } else if (
      netBox[0] > imageWidth  || netBox[0] < 0 ||
      netBox[1] > imageHeight || netBox[1] < 0 || 
      netBox[2] > imageWidth  || netBox[2] < 0 ||
      netBox[3] > imageHeight || netBox[3] < 0 || 
      (netBox[0] === 0 && netBox[1] === 0)
    ) {
      net = undefined;
    } else {
      // Fetch net
      net = {
        box: netBox,
        score: 1.0,
        width: imageWidth,
        height: imageHeight,
      }
    }
  
    const start = missingNet ? 3 : 4;
    for (let i = start; i < nodes.length; i++) {
      const node = nodes[i];
      const position = adjustPosition(node.position);
      let keypoint: Keypoint;
      if (
        position.x > imageWidth ||
        position.y > imageHeight || 
        position.x < 0 || 
        position.y < 0 || 
        // Edge case because out of image points get repositioned to (width, height)
        (position.x === 0 && position.y === 0)
      ) {
        // We pass (0, 0, 0) when not visible
        keypoint = {
          name: node.data.label || "",
          x: 0,
          y: 0,
          score: 0.0,
          width: imageWidth,
          height: imageHeight,
        }
      } else {
        keypoint = {
          name: node.data.label || "",
          x: position.x,
          y: position.y,
          score: 1.0,  // full confidence as a human
          width: imageWidth,
          height: imageHeight,
        }
      } 
      annotatedKeypoints.push(keypoint);
    }
    if (annotatedKeypoints.length === 0) return;
    try {
      await adjustKeypoints(keyframeId, annotatedKeypoints, hoop, backboard, net);
      toast.success('Successfully saved keypoints');
    } catch (err) {
      console.error(`Error in adjustKeypoints: `, err);
      toast.error('Error saving keypoints');
    } 
  }

  const getContent = () => {
    return (
      <div style={{ width: '100vw', height: '90vh' }}>
        <ReactFlow 
          nodes={nodes} 
          onNodesChange={onNodesChange}
          nodeTypes={nodeTypes}
          minZoom={0.5} // Adjust minimum zoom level
          maxZoom={2}   // Adjust maximum zoom level
          zoomOnScroll={true} // Enable zooming with scroll
          zoomOnPinch={true}  // Enable pinch to zoom
          panOnScroll={true}  // Enable panning with scroll
          panOnDrag={true}    // Enable panning with drag
        >
          <Controls />
          <MiniMap />
          <Background gap={12} size={1} />
        </ReactFlow>
        <Box 
          sx={{
            position: "absolute",
            top: 14,
            right: 110,
          }}
        >
          <Button 
            variant="contained"
            onClick={() => handleOnSave()}
            sx={{
              background: HOOPER_YELLOW,
            }}
          >
            <b>Save</b>
          </Button>
        </Box>
      </div>
    );
  };

  return (
    <Box>
      {loading ? <LoadingView loading={loading} /> : getContent()}
    </Box>
  );
}

export default KeyboardUI;

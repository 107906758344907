import React from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import type { FBSession } from "../shared/types";
import { 
  Chip,
  Paper, 
} from "@mui/material";
import { Link } from "react-router-dom";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { 
  DataGrid, 
  GridRowsProp, 
  GridColDef, 
  GridToolbar, 
} from "@mui/x-data-grid";
import { 
  HOOPER_BLUE,
  HOOPER_GREEN,
  HOOPER_RED,
  HOOPER_YELLOW,
  HOOPER_LIGHT_BLUE,
} from "../shared/constants";
import { SessionStatus } from "../shared/constants";

type InputProps = {
  sessions: FBSession[];
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
}));

const SessionsTable = ({ sessions }: InputProps) => {
  const rows: GridRowsProp = [
    ...sessions.map((session) => ({
      id: session.sessionId,
      rnfsPath: session.rnfsPath,
      videoUrl: session.videoUrl,
      userId: session.userId,
      username: session.username,
      visible: session.visible,
      status: session.status,
      numPlayers: session.numPlayers,
      sessionType: session.sessionType,
      sessionLength: session.sessionLength,
      city: session.city,
      state: session.state,
      country: session.country,
      longitude: session.longitude,
      latitude: session.latitude,
      createdAt: session.createdAt,
      updatedAt: session.updatedAt,
      numPausedTs: session.pausedTs ? session.pausedTs.length : 0,
      recordingSetup: session.recordingSetup,
      version: session.version,
      pointsSystem: session.pointSystem,
      numEditors: session.editors ? session.editors.length : 0,
      missingNet: session.missingNet,
      isSynced: !!session.syncedSessionId,
    })),
  ];

  const columns: GridColDef[] = [
    { 
      field: 'id', 
      headerName: 'Session ID', 
      width: 250, 
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        return <Link to={`/session/${params.value}`}>{params.value}</Link>;
      }
    },
    { 
      field: 'videoUrl', 
      headerName: 'Video', 
      width: 100, 
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        if ((params.value) && (params.value.includes("http"))) {
          return (
            <a href={params.value} target="_blank" rel="noreferrer">
              <FeaturedVideoIcon />
            </a>
          )
        } else {
          return <FeaturedVideoIcon color="disabled" />
        }
      },
    },
    { 
      field: 'userId', 
      headerName: 'User ID', 
      width: 300, 
      headerAlign: 'center',
      align: "center",
    },
    { 
      field: 'username', 
      headerName: 'Username', 
      width: 150, 
      headerAlign: 'center',
      align: "center",
    },
    { 
      field: 'visible', 
      headerName: 'Visible', 
      width: 100,
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        if (params.value) {
          return <CheckCircleIcon color="success" />;
        } else {
          return <ErrorIcon color="error" />;
        }
      }
    },
    { 
      field: 'status', 
      headerName: 'Status', 
      width: 150,
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        let backgroundColor: string;
        if (params.value === SessionStatus.CREATED) {
          backgroundColor = HOOPER_LIGHT_BLUE;
        } else if (params.value === SessionStatus.ERROR) {
          backgroundColor = HOOPER_RED;
        } else if (params.value === SessionStatus.PROCESSED) {
          backgroundColor = HOOPER_GREEN;
        } else if (params.value === SessionStatus.WAITING) {
          backgroundColor = HOOPER_YELLOW;
        } else if (params.value === SessionStatus.UPLOADED) {
          backgroundColor = "black";
        } else {
          backgroundColor = HOOPER_BLUE;
        }
        return (
          <Chip 
            label={params.value} 
            sx={{
              backgroundColor: backgroundColor,
              color: "white",
            }}
            variant="filled"
          />
        )
      }
    },
    { 
      field: 'version', 
      headerName: 'Version', 
      width: 100, 
      headerAlign: 'center',
      align: "center",
    },
    {
      field: 'numPlayers',
      headerName: '# Players',
      width: 100,
      headerAlign: 'center',
      align: "center",
    },
    {
      field: 'sessionType',
      headerName: 'Session Type',
      width: 100,
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        return (
          <Chip 
            label={params.value} 
            sx={{
              backgroundColor: HOOPER_BLUE,
              color: "white",
            }}
            variant="filled"
          />
        )
      }
    },
    { 
      field: 'sessionLength', 
      headerName: 'Session Length',
      width: 150, 
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        return Math.round(params.value * 10) / 10 + "s";
      },
    },
    {
      field: 'recordingSetup',
      headerName: 'Recording Setup',
      width: 100, 
      headerAlign: 'center',
      align: "center",
    },
    {
      field: 'pointsSystem',
      headerName: 'Points System',
      width: 100, 
      headerAlign: 'center',
      align: "center",
    },
    { 
      field: 'isSynced', 
      headerName: 'Synced?', 
      width: 100,
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        if (params.value) {
          return <CheckCircleIcon color="success" />;
        } else {
          return <CheckCircleIcon color="disabled" />;
        }
      }
    },
    {
      field: 'numPausedTs',
      headerName: '# Pauses',
      width: 100, 
      headerAlign: 'center',
      align: "center",
    },
    {
      field: 'numEditors',
      headerName: '# Editors',
      width: 100, 
      headerAlign: 'center',
      align: "center",
    },
    { 
      field: 'city', 
      headerName: 'City', 
      width: 100, 
      headerAlign: 'center',
      align: "center",
    },
    { 
      field: 'state', 
      headerName: 'State', 
      width: 100, 
      headerAlign: 'center',
      align: "center",
    },
    { 
      field: 'country', 
      headerName: 'Country', 
      width: 100, 
      headerAlign: 'center',
      align: "center",
    },
    { 
      field: 'createdAt', 
      headerName: 'Created At', 
      width: 150,
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        return dayjs(params.value * 1000).format("MM/DD/YY");
      }
    },
    { 
      field: 'updatedAt', 
      headerName: 'Updated At', 
      width: 150,
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        return dayjs(params.value * 1000).format("MM/DD/YY");
      }
    },
  ];

  return (
    <Paper>
      <div style={{ height: 1000, width: '100%' }}>
        <StyledDataGrid rows={rows} columns={columns} slots={{ toolbar: GridToolbar }} />
      </div>
    </Paper>
  );
}

export default SessionsTable;
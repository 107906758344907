import React from "react";
import { Link } from "react-router-dom";
import { 
  AppBar, 
  Box, 
  Button,
  CssBaseline, 
  Stack,
  Typography,
} from "@mui/material";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./components/ProtectedRoute";
import ControlPanel from "./components/ControlPanel";
import SessionView from "./components/SessionView";
import KeypointUI from "./components/KeypointUI";
import UserView from "./components/UserView";
import LoginView from "./components/LoginView";
import LogoutIcon from '@mui/icons-material/Logout';
import { HOOPER_BLUE, IS_AUTH_KEY } from "./shared/constants";


function App() {
  // Callback to "logout"
  const handleOnLogout = () => {
    localStorage.removeItem(IS_AUTH_KEY);

    window.location.reload();  // Refresh page
  }

  return (
    <div 
      className="App" 
      style={{ 
        backgroundColor: "#EEEDEB",
      }}
    >
      <Box>
        <CssBaseline />
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 5000,
            style: {
              background: HOOPER_BLUE,
              color: "#EEEDEB",
            },
          }}
        />
        <BrowserRouter>
          <AppBar 
            position="static" 
            elevation={1}
            sx={{
              backgroundColor: "#EEEDEB",
            }}
          >
            <Stack 
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Box
                sx={{
                  px: 3,
                  py: 2,
                }}
              >
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  > 
                    <img  src="/hooper-long.png" alt="" height={20} />
                    <Typography 
                      variant="h6" 
                      noWrap 
                      component="div" 
                      sx={{ 
                        fontWeight: "bold",
                        color: HOOPER_BLUE,
                      }}
                    >
                      Control Panel v2.0
                    </Typography>
                  </Stack>
                </Link>
              </Box>
              <Box sx={{ mr: 4}}>
                <Button 
                  onClick={handleOnLogout}
                >
                  <LogoutIcon sx={{ color: HOOPER_BLUE }} />
                </Button>
              </Box>
            </Stack>
          </AppBar>
          <Routes>
            <Route 
              path="/login"
              element={
                <LoginView />
              }
            />
            <Route 
              path="/"
              element={
                <ProtectedRoute redirectPath="/login">
                  <ControlPanel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/session/:id"
              element={
                <ProtectedRoute redirectPath="/login">
                  <SessionView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/session/keyframe/:id"
              element={
                <ProtectedRoute redirectPath="/login">
                  <KeypointUI />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/:id"
              element={
                <ProtectedRoute redirectPath="/login">
                  <UserView />
                </ProtectedRoute>
              }
            />
          </Routes> 
        </BrowserRouter>
      </Box>
    </div>
  );
}

export default App;

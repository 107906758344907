import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import type { 
  FBKeyframe,
  AdminIgnoreSessionShotRequest,
  AdminEditSessionShotPointsRequest,
  AdminEditSessionShotStatusRequest,
  FBShot,
  FBHighlight,
  FBSession,
  FBSessionComment,
} from "../shared/types";
import { Checkbox, Divider } from "@mui/material";
import { 
  deleteSession, 
  fetchSession, 
  setSessionStatus,
  setSessionMissingNet,
  fetchKeyframes,
  copyKeypoints,
  addPause,
  deletePause,
} from "../shared/services";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LoadingView from "./LoadingView";
import { 
  HOOPER_LIGHT_BLUE,
  HOOPER_RED,
  HOOPER_GREEN,
  HOOPER_YELLOW,
  HOOPER_BLUE, 
  SessionStatus, 
  SHOT_STATUS_MAKE,
} from "../shared/constants";
import {
  deleteKeyframe,
  resetKeyframes,
  doVideoInference,
  requestVideoCompression,
  doKeyframeInference,
  doVideoProcessing,
  copySession,
  updateVideoUrl,
  ignoreSessionShot,
  editSessionShotPoints,
  editSessionShotStatus,
} from "../shared/services";

/**
 * SessionView - Contains actions and limited data for a single session.
 */
const SessionView = () => {
  const params = useParams();
  const sessionId = params.id;

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [session, setSession] = useState<FBSession>();
  const [highlights, setHighlights] = useState<FBHighlight[]>([]);
  const [shots, setShots] = useState<FBShot[]>([]);
  const [comments, setComments] = useState<FBSessionComment[]>([]);
  const [keyframes, setKeyframes] = useState<FBKeyframe[]>([]);
  const [keyframeIds, setKeyframeIds] = useState<string[]>([]);
  const [deleteSessionAttempt, setDeleteSessionAttempt] = useState<boolean>(false);
  const [deleteKeyframeAttempt, setDeleteKeyframeAttempt] = useState<string>();
  const [resetKeyframesAttempt, setResetKeyframesAttempt] = useState<boolean>(false);
  // State variables below this are for forms
  const [newStatus, setNewStatus] = useState<SessionStatus>();
  const [newMissingNet, setNewMissingNet] = useState<boolean>();
  const [copyUserId, setCopyUserId] = useState<string>();
  const [copyKeyframeId, setCopyKeyframeId] = useState<string>();
  const [newVideoUrl, setNewVideoUrl] = useState<string>();
  // Reference for full video
  const fullVideoRef = useRef<any>();
  const [fullVideoCurrentTime, setFullVideoCurrentTime] = useState<number>(0);
  const [fullVideoDuration, setFullVideoDuration] = useState<number>(0);
  const isFullVideoRefUsed = !!fullVideoRef.current;

  // Hook to fetch new data on change of session ID 
  useEffect(() => {
    if (!sessionId) return;
    handleOnFetchData(sessionId);
  }, [sessionId]);

  // Hook to update a tracker on video timestamp
  useEffect(() => {
    const videoElement = fullVideoRef.current;
    const handleTimeUpdate = () => {
      if (videoElement) {
        setFullVideoCurrentTime(videoElement.currentTime);
      }
    };
    const handleLoadedMetadata = () => {
      if (videoElement) {
        setFullVideoDuration(videoElement.duration);
      }
    };
    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
    // Unsubscribe on exit
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, [isFullVideoRefUsed]);

  /* ================= API call ================= */

  const handleOnFetchData = async (sessionId: string) => {
    try {
      const [
        raw,
        keyframes,
      ] = await Promise.all([
        fetchSession(sessionId),
        fetchKeyframes(sessionId),
      ]);
      if (raw && raw.session) {
        setSession(raw.session);
        setNewStatus(raw.session.status as SessionStatus);
        setNewMissingNet(raw.session.missingNet);
      }
      if (raw && raw.highlights) {
        setHighlights(raw.highlights);
      } else {
        setHighlights([]);
      }
      if (raw && raw.shots) {
        setShots(raw.shots);
      } else {
        setShots([]);
      }
      if (keyframes.keyframes.length > 0) {
        setKeyframes(keyframes.keyframes);
        setKeyframeIds(keyframes.ids)
      } else {
        setKeyframes([]);
        setKeyframeIds([]);
      }
      if (raw && raw.comments) {
        setComments(raw.comments);
      } else {
        setComments([]);
      }
    } catch (e) {
      console.error("Error in `fetchSession` or `fetchKeyframes`", e);
    } finally {
      setLoading(false);
    }
  };

  /* ================= Callbacks ================= */

  /**
   * Callback to ignore a shot
   * @note Updates frontend and backend
   */
  const handleOnIgnoreShot = async (shotId: number, highlightId: string, ignore_value: boolean) => {
    const body: AdminIgnoreSessionShotRequest = {
      sessionId: sessionId!,
      shotId: shotId,
      highlightId: highlightId,
      ignore: ignore_value,
    }
    try {
      const success = await ignoreSessionShot(body);
      if (success) {
        // Update the shots object so we do not need to refresh
        let newShots: FBShot[] = [];
        for (let i = 0; i < shots.length; i++) {
          if (shots[i].shotId === shotId) {
            shots[i].ignore = ignore_value;
          }
          newShots.push(shots[i]);
        }
        setShots([...newShots]);
        // Update highlight to be not visible
        let newHighlights: FBHighlight[] = [];
        for (let i = 0; i < highlights.length; i++) {
          if (highlights[i].highlightId === highlightId) {
            highlights[i].visible = ignore_value ? 0 : 1;
          }
          newHighlights.push(highlights[i]);
        }
        setHighlights([...newHighlights]);
        // Send toast
        toast.success(`Shot ${shotId} ignored`);
      } else {
        toast.error(`Failed to ignore shot ${shotId}`);
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnIgnoreShot`: ', e);
      toast.error(`Error: ${e}`);
    }
  }
  
  /**
   * Callback to change if a shot is 2pt or 3pt
   * @note Updates frontend and backend
   */
  const handleOnEditShotPoints = async (shotId: number, points_value: number) => {
    const body: AdminEditSessionShotPointsRequest = {
      sessionId: sessionId!,
      shotId: shotId,
      points: points_value,
    }
    try {
      const success = await editSessionShotPoints(body); 
      if (success) {
         // Update the shots object so we do not need to refresh
         let newShots: FBShot[] = [];
         for (let i = 0; i < shots.length; i++) {
           if (shots[i].shotId === shotId) {
             shots[i].points = points_value;
             shots[i].isTwoPointShot = points_value === 2;
           }
           newShots.push(shots[i]);
         }
         setShots([...newShots]);
         // Send toast
         toast.success(`Shot ${shotId} updated`);
      } else {
        toast.error(`Failed to set points for shot ${shotId}`);
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnEditShotPoints`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Callback to change if a shot is in or out
   * @note Updates frontend and backend
   */
  const handleOnEditShotStatus = async (shotId: number, highlightId: string, status_value: number) => {
    const body: AdminEditSessionShotStatusRequest = {
      sessionId: sessionId!,
      shotId: shotId,
      status: status_value,
    }
    try {
      const success = await editSessionShotStatus(body);
      if (success) {
        // Update the shots object so we do not need to refresh
        let newShots: FBShot[] = [];
        for (let i = 0; i < shots.length; i++) {
          if (shots[i].shotId === shotId) {
            shots[i].status = status_value;
          }
          newShots.push(shots[i]);
        }
        setShots([...newShots]);
        // Update highlight to be not visible
        let newHighlights: FBHighlight[] = [];
        for (let i = 0; i < highlights.length; i++) {
          if (highlights[i].highlightId === highlightId) {
            highlights[i].isLowlight = !(status_value === SHOT_STATUS_MAKE);
          }
          newHighlights.push(highlights[i]);
        }
        setHighlights([...newHighlights]);
        // Send toast
        toast.success(`Shot ${shotId} updated`);
      } else {
        toast.error(`Failed to set status for shot ${shotId}`);
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnEditShotStatus`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Callback to delete session entirely
   * @note Updates frontend and backend
   */
  const handleOnDeleteSession = async () => {
    if (!session) return;
    if (!deleteSessionAttempt) {
      setDeleteSessionAttempt(true);
      return;
    }
    try {
      const success = await deleteSession(session.sessionId);
      if (success) {
        navigate("/");  // redirect out of this page since session is deleted
        return;
      } else {
        toast.error(`Failed to delete session ${session.sessionId}`);
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnDeleteSession`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Callback to delete keyframe
   * @note Updates frontend and backend
   */
  const handleOnDeleteKeyframe = async (keyframeId: string) => {
    if (!deleteKeyframeAttempt) {
      setDeleteKeyframeAttempt(keyframeId);
      return;
    }
    try {
      const success = await deleteKeyframe(keyframeId);
      if (success) {
        // Update keyframes and keyframe ids
        let newKeyframes: FBKeyframe[] = [];
        let newKeyframeIds: string[] = [];
        for (let i = 0; i < keyframes.length; i++) {
          if (keyframes[i].keyframeId === keyframeId) {
            continue;
          }
          newKeyframes.push(keyframes[i]);
          newKeyframeIds.push(keyframeIds[i]);
        }
        setKeyframes([...newKeyframes]);
        setKeyframeIds([...newKeyframeIds]);
      } else {
        toast.error(`Failed to delete keyframe ${keyframeId}`);
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnDeleteKeyframe`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Callback to reset all keyframes
   * @note Updates frontend and backend
   */
  const handleOnResetKeyframes = async () => {
    if (!session) return;
    if (!resetKeyframesAttempt) {
      setResetKeyframesAttempt(true);
      return;
    }
    try {
      const success = await resetKeyframes(session.sessionId);
      if (success) {
        setKeyframeIds([]);
        setKeyframes([]);
        setSession({...session, status: SessionStatus.WAITING});
        toast.success(`Reset keyframes`);
      } else {
        toast.error(`Failed to reset keyframes`);
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnResetKeyframes`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Callback to delete a paused timestamp
   */
  const handleOnDeletePause = async (timestamp: number) => {
    if (!session) return;
    try {
      // Find keyframe for this timestamp
      let selectedId: string | undefined = undefined;
      for (let i = 0; i < keyframes.length; i++) {
        if (keyframes[i].timestamp === timestamp) {
          selectedId = keyframeIds[i];
        }
      }
      const success = await deletePause(session.sessionId, timestamp, selectedId);
      if (success) {
        // Remove paused timestamp from session
        const newPausedTs = session.pausedTs.filter(x => x !== timestamp);
        setSession({...session, pausedTs: [...newPausedTs]});
        // May not need to change if we just ignored keyframes
        if (selectedId !== undefined) {
          // Remove keyframe for that timestamp
          let newKeyframeIds: string[] = [];
          let newKeyframes: FBKeyframe[] = [];
          for (let i = 0; i < keyframes.length; i++) {
            if (keyframes[i].timestamp !== timestamp) {
              newKeyframeIds.push(keyframeIds[i]);
              newKeyframes.push(keyframes[i]);
            }
          }
          setKeyframeIds([...keyframeIds]);
          setKeyframes([...keyframes]);
        }
        // Notify user
        toast.success(`Deleted paused timestamp`);
      } else {
        toast.error(`Failed to delete paused timestamp`);
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnDeletePause`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Callback to add a paused timestamp
   */
  const handleOnAddPause = async (timestamp: number) => {
    if (!session) return;
    // Round to int
    timestamp = Math.round(timestamp);
    try {
      // Check if timestamp is already in paused ts
      if (!!session.pausedTs.find(x => x === timestamp)) {
        toast.error(`Pause ${timestamp} already exists`);
        return;
      }
      const success = await addPause(session.sessionId, timestamp);
      if (success) {
        // Add paused timestamp from session (make sure to sort)
        let newPausedTs = [...session.pausedTs, timestamp];
        newPausedTs.sort();
        setSession({...session, pausedTs: [...newPausedTs]});
        // Notify user
        toast.success(`Added paused timestamp`);
      } else {
        toast.error(`Failed to delete paused timestamp`);
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnAddPause`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Respond to a user selection for a new status
   * @param event 
   */
  const handleOnChangeStatus = (event: SelectChangeEvent) => {
    const newStatus = event.target.value as string;
    setNewStatus(newStatus as SessionStatus);
  }

  /**
   * Changes the session status
   * @note Edits frontend and backend
   */
  const handleOnSetStatus = async () => {
    if (!session) return;
    if (!newStatus) return;
    if (session.status === newStatus) {
      toast.error("Cannot set status to same value");
      return;
    }
    try {
      const success = await setSessionStatus(session.sessionId, newStatus);
      if (success) {
        toast.success("Updated session status");
        setSession({...session, status: newStatus as string})
      } else {
        toast.error(`Failed to set session status to "${newStatus}"`);
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnSetStatus`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Copies a session to a new ID
   * @note Edits frontend and backend
   */
  const handleOnCopySession = async () => {
    if (!session) return;
    if (!copyUserId) {
      toast.error("No user ID specified");
      return;
    }
    if (session.userId === copyUserId) {
      toast.error("Cannot copy session to same user");
      return;
    }
    try {
      const success = await copySession(session.sessionId, copyUserId);
      if (success) {
        toast.success("Copied session");  
      } else {
        toast.error(`Failed to copy session`);
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnCopySession`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Copies keypoints/hoop/backboard/net to another keyframe
   * @note Edits frontend and backend
   */
  const handleOnCopyKeypoints = async (targetKeyframeId: string) => {
    if (!session) return;
    if (!targetKeyframeId) return;
    if (!copyKeyframeId) {
      toast.error("No keyframe ID specified");
      return;
    }
    try {
      const success = await copyKeypoints(targetKeyframeId, copyKeyframeId);
      if (success) {
        // Fetch the new keyframes
        const newKeyframes = await fetchKeyframes(session.sessionId);
        if (newKeyframes.keyframes.length > 0) {
          setKeyframes(newKeyframes.keyframes);
          setKeyframeIds(newKeyframes.ids)
        } else {
          setKeyframes([]);
          setKeyframeIds([]);
        }
        toast.success("Copied keypoints");
      } else {
        toast.error("Failed to copy keypoints");
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnCopyKeypoints`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Updates a video URL
   * @note Edits frontend and backend
   */
  const handleOnUpdateVideoUrl = async () => {
    if (!session) return;
    if (!newVideoUrl) {
      toast.error("No video URL specified");
      return;
    }
    if (session.videoUrl === newVideoUrl) {
      toast.error("Cannot update video URL to the same URL");
      return;
    }
    try {
      const success = await updateVideoUrl(session.sessionId, newVideoUrl);
      if (success) {
        setSession({...session, videoUrl: newVideoUrl});
        toast.success("Updated session video URL");
      } else {
        toast.error("Failed to set video URL");
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnUpdateVideoUrl`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Updates if a net is missing or not
   * @note Edits frontend and backend
   */
  const handleOnSetNetPresence = async () => {
    if (!session) return;
    if (newMissingNet === null || newMissingNet === undefined) {
      toast.error("No missing net specified");
      return;
    }
    if (session.missingNet === newMissingNet) {
      toast.error("Cannot update missing net to the same value");
      return;
    }
    try {
      const success = await setSessionMissingNet(session.sessionId, newMissingNet);
      if (success) {
        setSession({...session, missingNet: newMissingNet});
        toast.success("Updated missing net property in session");
      } else {
        toast.error("Failed to set missing net");
      }
    } catch (e) {
      console.error('Error in `SessionView.handleOnSetNetPresence`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Respond to a user selection for a new missing net value
   * @param event 
   */
  const handleOnChangeNetPresence = (event: SelectChangeEvent) => {
    const netPresence = event.target.value as string;
    setNewMissingNet(netPresence === "missing");
  }

  /**
   * Submits a job for video inference
   * @param liteMode 
   * @returns 
   */
  const handleOnRerunVideoInference = async (liteMode: boolean) => {
    if (!session) return;
    try {
      await doVideoInference(session.sessionId, liteMode);  
      toast.success("Rerunning video inference");
    } catch (e) {
      console.error('Error in `SessionView.handleOnRerunVideoInference`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Submits a job for video compression
   * @param liteMode 
   * @returns 
   */
  const handleOnRequestVideoCompression = async () => {
    if (!session) return;
    try {
      await requestVideoCompression(session.sessionId);
      toast.success("Request video compression");
    } catch (e) {
      console.error('Error in `SessionView.handleOnRequestVideoCompression`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Submits a job for keyframe inference
   * @param liteMode 
   * @returns 
   */
  const handleOnRerunKeyframeInference = async () => {
    if (!session) return;
    try {
      await doKeyframeInference(session.sessionId);
      toast.success("Rerunning keyframe inference");
    } catch (e) {
      console.error('Error in `SessionView.handleOnRerunKeyframeInference`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Submits a job for video processing pipeline
   * @returns 
   */
  const handleOnRerunVideoProcessing = async () => {
    if (!session) return;
    try {
      await doVideoProcessing(session.sessionId);
      toast.success("Rerunning video processing");
    } catch (e) {
      console.error('Error in `SessionView.handleOnRerunVideoProcessing`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /* ================= Components ================= */

  const getPropertyCard = (title: string, value: any) => {
    return (
      <Paper 
        sx={{
          px: 4,
          py: 3,
          borderBottom: `2px solid ${HOOPER_BLUE}`
        }}
      >
        <button 
          onClick={() => {
            navigator.clipboard.writeText(value);
            toast.success("Copied");
          }}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
            margin: 0,
            fontSize: 'inherit',
            color: 'inherit',
            cursor: 'pointer',
            textAlign: 'left',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
          <Typography variant="body1">
            {value.length > 30 ? value.substring(0, 27) + "..." : value}
          </Typography>
        </button>
      </Paper>
    );
  }

  const getSessionContent = () => {
    if (!session) return null;
    return (
      <Grid container columns={12} spacing={2}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Session ID", session.sessionId)}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Predictions file", session.predictions || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("RNFS File", session.rnfsPath || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Video URL", session.videoUrl || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Video Width", session.videoWidth || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Video Height", session.videoHeight || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Video FPS", session.videoFps || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Video Preview", session.videoPreview || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Video length", session.sessionLength || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("User ID", session.userId || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Username", session.username || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Visible", session.visible || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Version", session.version || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Minimap", session.minimap || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Session Type", session.sessionType || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Session Mode", session.sessionMode || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("# Players", session.numPlayers || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Recording Setup", session.recordingSetup || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Recording Start Timestamp", session.recordingStartTs || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Recording End Timestamp", session.recordingEndTs || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Trimmed Start Timestamp", session.trimmedStartTs || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Trimmed End Timestamp", session.trimmedEndTs || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Trimmed Duration", session.trimmedDuration || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Point System", session.pointSystem || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Synced Session", session.syncedSessionId || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Has Teams?", session.hasTeams ? "Yes" : "No")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard(
            "Teams Names", 
            session.teamNames && session.teamNames.length > 0 
            ? session.teamNames.join(", ")
            : "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
        {getPropertyCard(
            "Editor Names", 
            session.editors && session.editors.length > 0 
            ? session.editors.join(", ")
            : "N/A")}
        </Grid>      
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("# Editors", session.editors?.length || 0)}
        </Grid>        
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("# Highlights", highlights.filter(x => !x.isLowlight).length || 0)}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("# Lowlights", highlights.filter(x => x.isLowlight).length || 0)}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("# Keyframes", session.keyframes?.length || 0)}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("# Comments", comments.length || 0)}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Notified?", session.messaged ? "Yes" : "No")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Missing Net?", session.missingNet ? "Yes" : "No")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("City", session.city || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("State", session.state || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Country", session.country || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Longitude", session.longitude || "N/A")}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {getPropertyCard("Latitude", session.latitude || "N/A")}
        </Grid>
      </Grid>
    );
  }

  const getChipColor = (status: string) => {
    let backgroundColor: string;
    if (status === SessionStatus.CREATED) {
      backgroundColor = HOOPER_LIGHT_BLUE;
    } else if (status === SessionStatus.ERROR) {
      backgroundColor = HOOPER_RED;
    } else if (status === SessionStatus.PROCESSED) {
      backgroundColor = HOOPER_GREEN;
    } else if (status === SessionStatus.WAITING) {
      backgroundColor = HOOPER_YELLOW;
    } else if (status === SessionStatus.UPLOADED) {
      backgroundColor = "black";
    } else {
      backgroundColor = HOOPER_BLUE;
    }
    return backgroundColor;
  }

  const getActionsContent = () => {
    if (!session) return null;
    return (
      <Paper
        sx={{
          px: 4,
          pb: 3,
          pt: 1,
          borderBottom: `2px solid ${HOOPER_BLUE}`
        }}
      >
        <Box sx={{ pt: 2 }}>
          <Box sx={{pb: 2}}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>Processing Actions</Typography>
          </Box>
          <Grid
            container 
            direction="row"
            spacing={3}
            columns={12}
          > 
            <Grid item md={3} sm={6} xs={12}>
              <Button 
                fullWidth 
                variant="contained" 
                sx={{ backgroundColor: HOOPER_BLUE }}
                onClick={() => handleOnRerunVideoInference(false)}
              >
                <b>Video Inference</b>
              </Button>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Button 
                fullWidth 
                variant="contained" 
                sx={{ backgroundColor: HOOPER_BLUE }}
                onClick={() => handleOnRerunVideoInference(true)}
              >
                <b>Lite Video Inference</b>
              </Button>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Button 
                fullWidth 
                variant="contained" 
                sx={{ backgroundColor: HOOPER_BLUE }}
                onClick={() => handleOnRerunKeyframeInference()}
              >
                <b>Keyframe Inference</b>
              </Button>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Button 
                fullWidth 
                variant="contained" 
                color="error"
                onClick={handleOnDeleteSession}
              >
                <b>{deleteSessionAttempt ? "Are you sure?" : "Delete Session"}</b>
              </Button>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Button 
                fullWidth 
                variant="contained" 
                color="error"
                onClick={handleOnResetKeyframes}
              >
                <b>{resetKeyframesAttempt ? "Are you sure?" : "Reset All Keyframes"}</b>
              </Button>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Button 
                fullWidth 
                variant="outlined" 
                onClick={() => handleOnRerunVideoProcessing()}
              >
                <b>Video Processing</b>
              </Button>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Button 
                fullWidth 
                variant="outlined" 
                onClick={() => handleOnRequestVideoCompression()}
              >
                <b>Video Compression</b>
              </Button>
            </Grid>
          </Grid>
          <Grid
            container 
            direction="row"
            spacing={3}
            sx={{ pt: 3 }}
          > 
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{pb: 2}}>
                <Typography variant="button" sx={{ fontWeight: "bold" }}>Set session status</Typography>
              </Box>
              <Stack 
                direction={"row"}
                spacing={4}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}  
              >
                <Box sx={{ width: "100%" }}>
                  <FormControl fullWidth>
                    <InputLabel id="session-status-input">Status</InputLabel>
                    <Select
                      labelId="session-status-input"
                      id="session-status-select"
                      value={newStatus}
                      label="Status"
                      onChange={handleOnChangeStatus}
                    >
                      <MenuItem value={SessionStatus.CREATED}>
                        <Chip 
                          label={"created"} 
                          sx={{
                            backgroundColor: getChipColor("created"),
                            color: "white",
                          }}
                          variant="filled"
                        />
                      </MenuItem>
                      <MenuItem value={SessionStatus.UPLOADED}>
                        <Chip 
                          label={"uploaded"} 
                          sx={{
                            backgroundColor: getChipColor("uploaded"),
                            color: "white",
                          }}
                          variant="filled"
                        />
                      </MenuItem>
                      <MenuItem value={SessionStatus.WAITING}>
                        <Chip 
                          label={"waiting"} 
                          sx={{
                            backgroundColor: getChipColor("waiting"),
                            color: "white",
                          }}
                          variant="filled"
                        />
                      </MenuItem>
                      <MenuItem value={SessionStatus.PROCESSED}>
                        <Chip 
                          label={"processed"} 
                          sx={{
                            backgroundColor: getChipColor("processed"),
                            color: "white",
                          }}
                          variant="filled"
                        />
                      </MenuItem>
                      <MenuItem value={SessionStatus.TAGGED}>
                        <Chip 
                          label={"tagged"} 
                          sx={{
                            backgroundColor: getChipColor("tagged"),
                            color: "white",
                          }}
                          variant="filled"
                        />
                      </MenuItem>
                      <MenuItem value={SessionStatus.ERROR}>
                        <Chip 
                          label={"error"} 
                          sx={{
                            backgroundColor: getChipColor("error"),
                            color: "white",
                          }}
                          variant="filled"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Button 
                    variant="contained" 
                    sx={{ backgroundColor: HOOPER_YELLOW }}
                    onClick={handleOnSetStatus}
                  >
                    <b>Update</b>
                  </Button>
                </Box>
              </Stack>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{pb: 2}}>
                <Typography variant="button" sx={{ fontWeight: "bold" }}>Copy session to another user</Typography>
              </Box>
              <Stack 
                direction={"row"}
                spacing={4}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}  
              >
                <Box sx={{ width: "100%" }}>
                  <TextField 
                    fullWidth
                    id="copy-user-id" 
                    label="User ID" 
                    variant="outlined" 
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCopyUserId(event.target.value);
                    }}
                  />
                </Box>
                <Box>
                  <Button 
                    variant="contained" 
                    sx={{ backgroundColor: HOOPER_YELLOW }}
                    onClick={handleOnCopySession}
                  >
                    <b>Copy</b>
                  </Button>
                </Box>
              </Stack>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{pb: 2}}>
                <Typography variant="button" sx={{ fontWeight: "bold" }}>Update Video URL</Typography>
              </Box>
              <Stack 
                direction={"row"}
                spacing={4}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}  
              >
                <Box sx={{ width: "100%" }}>
                  <TextField 
                    fullWidth
                    id="update-video-url" 
                    label="Video URL" 
                    variant="outlined" 
                    defaultValue={session.videoUrl}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setNewVideoUrl(event.target.value);
                    }}
                  />
                </Box>
                <Box>
                  <Button 
                    variant="contained" 
                    sx={{ backgroundColor: HOOPER_YELLOW }}
                    onClick={handleOnUpdateVideoUrl}
                  >
                    <b>Update</b>
                  </Button>
                </Box>
              </Stack>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{pb: 2}}>
                <Typography variant="button" sx={{ fontWeight: "bold" }}>Set Missing Net</Typography>
              </Box>
              <Stack 
                direction={"row"}
                spacing={4}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}  
              >
                <Box sx={{ width: "100%" }}>
                  <FormControl fullWidth>
                    <InputLabel id="session-missing-net">Net Presence</InputLabel>
                    <Select
                      labelId="session-missing-net-input"
                      id="session-missing-net-select"
                      value={newMissingNet ? "missing" :"present"}
                      label="Net Presence"
                      onChange={handleOnChangeNetPresence}
                    >
                      <MenuItem value={"present"}>
                        <Chip 
                          label={"present"} 
                          sx={{
                            backgroundColor: HOOPER_GREEN,
                            color: "white",
                          }}
                          variant="filled"
                        />
                      </MenuItem>
                      <MenuItem value={"missing"}>
                        <Chip 
                          label={"missing"} 
                          sx={{
                            backgroundColor: HOOPER_RED,
                            color: "white",
                          }}
                          variant="filled"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Button 
                    variant="contained" 
                    sx={{ backgroundColor: HOOPER_YELLOW }}
                    onClick={handleOnSetNetPresence}
                  >
                    <b>Update</b>
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }

  const getKeyframesContent = () => {
    if (!session) return null;
    if (keyframes.length === 0) {
      return (
        <Box>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 16,
            }}
          >
            No keyframes found.
          </Typography>
        </Box>
      )
    }
    const gridItems = keyframes.map((keyframe, index) => {
      const keyframeId = keyframeIds[index];
      return (
        <Grid item xl={3} lg={4} sm={6} md={6} xs={12} key={`session-${sessionId}-keyframe-${index}`}>
          <Box>
            <Typography
              style={{
                color: "#758694",
                fontSize: 12,
              }}
            >
              keyframe ID: {keyframeId}
            </Typography>
            <Typography
              style={{
                color: "#758694",
                fontSize: 12,
              }}
              gutterBottom
            >
              keyframe timestamp: {Math.round(10 * keyframe.timestamp) / 10}s
            </Typography>
            <Button
              onClick={() => navigate(
                `/session/keyframe/${keyframe.keyframeId}`, { 
                  state: { 
                    sessionId: sessionId, 
                    missingNet: newMissingNet, 
                  }
                }
              )}
              sx={{ p: 0, m: 0}}
            >
              <img 
                src={keyframe.frameUrl}
                width={"100%"}
                style={{ borderRadius: 4 }}
                alt=""
              />
            </Button>
            <Box sx={{ py: 1 }}>
              <Box sx={{pb: 1}}>
                <Typography 
                  variant="button" 
                  sx={{ 
                    color: "#758694",
                    fontSize: 12, 
                  }}
                >
                  Copy another keyframe
                </Typography>
              </Box>
              <Stack 
                direction={"row"}
                spacing={4}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}  
              >
                <Box sx={{ width: "100%" }}>
                  <TextField 
                    fullWidth
                    id="copy-keypoints-id" 
                    label="Keyframe ID" 
                    variant="outlined" 
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCopyKeyframeId(event.target.value);
                    }}
                  />
                </Box>
                <Box>
                  <Button 
                    variant="contained" 
                    sx={{ backgroundColor: HOOPER_YELLOW }}
                    onClick={() => handleOnCopyKeypoints(keyframeId)}
                  >
                    <b>Copy</b>
                  </Button>
                </Box>
              </Stack>
            </Box>
            <Box sx={{ pt: 1 }}>
              <Button 
                fullWidth 
                variant="contained" 
                color="error"
                onClick={() => handleOnDeleteKeyframe(keyframeId)}
              >
                <b>{deleteKeyframeAttempt === keyframeId ? "Confirm?" : "Delete"}</b>
              </Button>
            </Box>
          </Box>
        </Grid>
      );
    });
    return (
      <Grid
        container 
        direction="row"
        spacing={2}
        columns={12}
      > 
        {gridItems}
      </Grid>
    );
  }

  const getHighlightsItems = (highlights: FBHighlight[], shots: FBShot[]) => {
    const gridItems = highlights.map((highlight, index) => {
      const shot = shots.find(shot => shot.shotId === highlight.shotId);
      if (!shot) return null;
      return (
        <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={`session-${sessionId}-highlight-${index}`}>
          <video width="100%" controls style={{ borderRadius: 6 }}>
            <source src={highlight.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Box sx={{pt: 1}}>
          <Paper 
            sx={{
              px: 1,
              py: 1,
              borderBottom: `2px solid ${HOOPER_BLUE}`
            }}
          >
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                shotId
              </Typography>
              <Typography sx={{fontSize: 10}}>
                {highlight.shotId}
              </Typography>
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                status
              </Typography>
              <Typography sx={{fontSize: 10}}>
                {shot?.status}
              </Typography>
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                distance
              </Typography>
              <Typography sx={{fontSize: 10}}>
                {shot!.distanceToHoop ? Math.round(shot!.distanceToHoop * 10) / 10 : "N/A"}
              </Typography>
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                location
              </Typography>
              <Typography sx={{fontSize: 10}}>
                {shot!.location ? `${Math.round(shot!.location[0] * 10) / 10}, ${Math.round(shot!.location[1] * 10) / 10}` : "N/A"}
              </Typography>
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                is 2pt
              </Typography>
              <Checkbox 
                checked={shot!.isTwoPointShot ? !!shot!.isTwoPointShot: false} 
                sx={{p: 0}} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!shot) return;
                  if (shot.shotId === undefined) return;
                  const checked = event.target.checked;
                  handleOnEditShotPoints(shot.shotId!, checked ? 2 : 3);
                }}
              />
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                ignored?
              </Typography>
              <Checkbox 
                checked={shot!.ignore ? !!shot!.ignore: false} 
                sx={{p: 0}} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!shot) return;
                  if (shot.shotId === undefined) return;
                  const checked = event.target.checked;
                  handleOnIgnoreShot(shot.shotId!, highlight.highlightId, checked);
                }}
              />
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                visible
              </Typography>
              <Checkbox disabled checked={highlight.visible ? !!highlight.visible: false} sx={{p: 0}} />
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                in feed
              </Typography>
              <Checkbox disabled checked={highlight.showInFeed ? !!highlight.showInFeed: false} sx={{p: 0}} />
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                length
              </Typography>
              <Typography sx={{fontSize: 10}}>
                {highlight.length}s
              </Typography>
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                detected@
              </Typography>
              <Typography sx={{fontSize: 10}}>
                {Math.round(shot!.timestampCreatedAt * 10) / 10}s
              </Typography>
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                classified@
              </Typography>
              <Typography sx={{fontSize: 10}}>
                {Math.round(shot!.timestampUpdatedAt * 10) / 10}s
              </Typography>
            </Box>
            <Button 
              fullWidth 
              variant="contained" 
              sx={{ backgroundColor: shot!.status === 1 ? HOOPER_RED : HOOPER_BLUE, mt: 1 }}
              onClick={() => {
                if (!shot) return;
                if (shot.shotId === undefined) return;
                let newStatus: number; 
                if (shot.status === 1) {
                  newStatus = 2;
                } else if (shot.status === 2) {
                  newStatus = 1;
                } else {
                  newStatus = 1;
                }
                handleOnEditShotStatus(shot.shotId!, highlight.highlightId, newStatus);
              }}
            >
              <b>{shot!.status === 1 ? "False positive" : "False negative"}</b>
            </Button>
          </Paper>
          </Box>
        </Grid>
      );
    });
    return gridItems;
  }

  const getHighlightsContent = () => {
    if (!session) return null;
    const myHighlights = highlights.filter(x => !x.isLowlight);
    if (myHighlights.length === 0) {
      return (
        <Box>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 16,
            }}
          >
            No highlights found.
          </Typography>
        </Box>
      )
    }
    return (
      <Grid
        container 
        direction="row"
        spacing={2}
        columns={14}
      > 
        {getHighlightsItems(myHighlights, shots)}
      </Grid>
    );
  }

  const getLowlightsContent = () => {
    if (!session) return null;
    const myLowlights = highlights.filter(x => x.isLowlight);
    if (!myLowlights || myLowlights.length === 0) {
      return (
        <Box>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 16,
            }}
          >
            No lowlights found.
          </Typography>
        </Box>
      )
    }
    return (
      <Grid
        container 
        direction="row"
        spacing={2}
        columns={14}
      > 
        {getHighlightsItems(myLowlights, shots)}
      </Grid>
    );
  }

  const getContent = () => {
    if (!session) return;
    return (
      <Box 
        sx={{ 
          flexGrow: 1,
          flex: 1,
          py: 4,
          px: 8,
        }}
      >
        <Box sx={{ pb: 4 }}>
          <Grid
            container 
            direction="row"
            spacing={2}
            sx={{
              pb: 4,
            }}
          > 
            <Grid item>
              <Chip 
                label={session.status} 
                sx={{
                  backgroundColor: getChipColor(session.status),
                  color: "white",
                  mr: 1,
                }}
                variant="filled"
              />
            </Grid>
            <Grid item>
              <Chip 
                label={`version ${session.version}`} 
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  mr: 1,
                }}
                variant="filled"
              />
            </Grid>
            <Grid item>
              {session.visible ? (
                <Chip 
                  label={`visible`} 
                  sx={{
                    backgroundColor: HOOPER_GREEN,
                    color: "white",
                    mr: 1,
                  }}
                  variant="filled"
                />
              ) : (
                <Chip 
                  label={`deleted`} 
                  sx={{
                    backgroundColor: HOOPER_RED,
                    color: "white",
                    mr: 1,
                  }}
                  variant="filled"
                />
              )}
            </Grid>
            <Grid item>
              <Chip 
                label={`${session.sessionMode}`} 
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  mr: 1,
                }}
                variant="filled"
              />
            </Grid>
            <Grid item>
              <Chip 
                label={`${session.sessionType}`} 
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  mr: 1,
                }}
                variant="filled"
              />
            </Grid>
            <Grid item>
              <Chip 
                label={`${session.numPlayers} players`} 
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  mr: 1,
                }}
                variant="filled"
              />
            </Grid>
            {session.recordingSetup && ( 
              <Grid item>
                <Chip 
                  label={`recording: ${session.recordingSetup}`} 
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    mr: 1,
                  }}
                  variant="filled"
                />
              </Grid> 
            )}
            {session.pointSystem && (
              <Grid item>
                <Chip 
                  label={`point system: ${session.pointSystem}`} 
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    mr: 1,
                  }}
                  variant="filled"
                />
              </Grid>
            )}
            {session.syncedSessionId && ( 
              <Grid item>
                <Chip 
                  label={`synced: ${session.syncedSessionId}`}
                  sx={{
                    backgroundColor: HOOPER_BLUE,
                    color: "white",
                    mr: 1,
                  }}
                  variant="filled"
                />
              </Grid>
            )}
          </Grid>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 20,
            }}
            gutterBottom
          >
            ID: {sessionId}
          </Typography>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 18,
            }}
          >
            Recorded by <Link to={`/user/${session.userId}`}>{session.username}</Link> on {dayjs.unix(session.createdAt).format("MM/D/YY hh:mm:ss A")}. Last updated {dayjs.unix(session.updatedAt).format("MM/D/YY hh:mm:ss A")}.
          </Typography>
          <Grid container columns={12} spacing={8} sx={{ pt: 4 }}>
            <Grid item md={8} sm={12} xs={12}>
              <video ref={fullVideoRef} width="100%" controls style={{ borderRadius: 6 }}>
                <source src={session.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Paper
                sx={{
                  px: 4,
                  py: 3,
                  borderBottom: `2px solid ${HOOPER_BLUE}`
                }}
              >
                <Box sx={{pb: 2}}>
                  <Typography variant="button" sx={{ fontWeight: "bold" }}>Paused Timestamps</Typography>
                </Box>
                {session.pausedTs.length === 0 && (
                  <Box sx={{pb: 2}}>
                    <Typography
                       style={{
                        color: "#758694",
                        fontSize: 16,
                      }}
                    >
                      No pauses found.
                    </Typography>
                  </Box>
                )}
                <Box>
                  {session.pausedTs.map(ts => (
                    <Box sx={{pb: 2, display: "flex", flex: 1, flexDirection: "row", gap: 2}}>
                      <TextField 
                        fullWidth 
                        label="Timestamp" 
                        variant="outlined" 
                        disabled 
                        defaultValue={ts} 
                      />
                      <Button 
                        variant="contained" 
                        color="error"
                        onClick={() => handleOnDeletePause(ts)}
                      >
                        <b>Delete</b>
                      </Button>
                    </Box>
                  ))}
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ pb: 1}}>
                  <Typography
                    style={{
                      color: "#758694",
                      fontSize: 16,
                    }}
                  >
                    {Math.round(fullVideoCurrentTime * 10) / 10} / {Math.round(fullVideoDuration * 10 / 10)} seconds ({fullVideoDuration > 0 ? Math.round(fullVideoCurrentTime * 1000 / fullVideoDuration) / 100 : 0}%) 
                  </Typography>
                </Box>
                <Box sx={{pb: 1}}>
                  <Button 
                    fullWidth 
                    variant="contained" 
                    sx={{ backgroundColor: HOOPER_BLUE }}
                    onClick={() => handleOnAddPause(fullVideoCurrentTime)}
                  >
                    <b>Add paused timestamp</b>
                  </Button>
                </Box>
                <Box sx={{ pt: 1}}>
                  <Typography
                    style={{
                      color: "#758694",
                      fontSize: 14,
                    }}
                  >
                    Adding a paused timestamp does not automatically update keyframes. If you have added a new paused timestamps, please run keyframe inference. Removing a paused timestamp will delete a keyframe. Be careful.
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ pb: 4 }}>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 18,
              paddingBottom: 8,
            }}
            gutterBottom
          >
            Action Controls
          </Typography>
          {getActionsContent()}
        </Box>
        <Box sx={{ pb: 4 }}>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 18,
              paddingBottom: 8,
            }}
            gutterBottom
          >
            Keyframes
          </Typography>
          {getKeyframesContent()}
        </Box>
        <Box sx={{ pb: 4 }}>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 18,
              paddingBottom: 8,
            }}
            gutterBottom
          >
            {`Highlights (${highlights.filter(x => !x.isLowlight).length || 0})`}
          </Typography>
          {getHighlightsContent()}
        </Box>
        <Box sx={{ pb: 4 }}>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 18,
              paddingBottom: 8,
            }}
            gutterBottom
          >
            {`Lowlights (${highlights.filter(x => x.isLowlight).length || 0})`}
          </Typography>
          {getLowlightsContent()}
        </Box>
        <Box>
         <Typography 
            style={{
              color: "#758694",
              fontSize: 18,
              paddingBottom: 8,
            }}
            gutterBottom
          >
            Properties
          </Typography>
          {getSessionContent()}
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      {loading ? <LoadingView loading={loading} /> : getContent()}
    </Box>
  );
};

export default SessionView;